
// Min width 1120px
@media (min-width: 1200px) {

    .container-fixed {
        padding: 0 36px;
        margin: 0 auto;
        max-width: 1200px;
    }

    .container-fixed-inner {
      max-width: 760px;
      margin-top: 28px;
    }
}


// Min width 1550px
@media (min-width: 1250px) {
  .container-fixed {
    padding: 0;
    max-width: 1230px;
  }

  .container-fixed-inner {
    max-width: 760px;
  }

}
