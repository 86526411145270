// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
@import "./containers.scss";

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #000000;
  --ion-color-primary-rgb: 0, 0, 0;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #333333;
  --ion-color-primary-tint: #4d4d4d;

  /** secondary **/
  --ion-color-secondary: #3dc2ff; //not set
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff; //not set
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #05b6ff;
  --ion-color-success-rgb: 5, 182, 255;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ff9300;
  --ion-color-warning-rgb: 255, 147, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #ff0000;
  --ion-color-danger-rgb: 255, 0, 0;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #0f3c50; //high emphasis text
  --ion-color-dark-rgb: 15, 60, 80;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #658398; //merium emphasis text
  --ion-color-medium-rgb: 101, 131, 152;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #91a9ba; //low emphasis text
  --ion-color-light-rgb: 145, 169, 186;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-primary-light: #1a1a1a;
  --ion-color-primary-dark: #000000;
  --ion-color-lines: #e1e8ed;
  --ion-color-lines-rgb: 225, 232, 237;

  --ion-color-background: #f6f9fe;
  --ion-color-background-rgb: 246, 249, 254;

  --ion-color-white: #FFFFFF;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white80: rgba(255, 255, 255, .8);
  --ion-color-white50: rgba(255, 255, 255, .5);
  --ion-color-white20: rgba(255, 255, 255, .2);


  --ion-color-black: #000000;

  --ion-color-pink: #C8005D;
  --ion-color-pink-28: rgba(200, 0, 93, 0.28);
  --ion-color-burgundy: #872046;
  --ion-color-emerald: #008083;
  --ion-color-emerald-dark: #006265;
  --ion-color-cobalt: #002FA2;
  --ion-color-disabled: #CBD5E1;

  --ion-color-comp-yellow: #f9e300;
  --ion-color-comp-yellow-dark: #ddc930;
  --ion-color-comp-orange: #ff5702;
  --ion-color-comp-purple: #cb045d;
  --ion-color-comp-brown: #732f32;
  --ion-color-comp-green-dark: #006265;
  --ion-color-transparent: transparent;

  --ion-color-title-text: #002333;
  --ion-color-description-text: #314369;
  --ion-color-border: #bdd4e5;

  --ion-color-gradient: #ae00ce;
  --ion-color-shadow: #7809f348;
  --ion-color-bg-light: #0023335C;
  --ion-color-bg-primary: #0736B91A;

  --ion-color-toast-border: #707070;
  --ion-color-premium-bg: #E5EAF8;
  --ion-color-plans-bg: #FFFFFF29;

  --ion-color-version: #A4B4BF;

  --ion-color-orange-border: #E58501;
  --ion-color-error: #EA4040;
  --ion-color-error-bg: #EA40401A;

  --ion-color-notification-bg: #F6F8FA;

  --ion-color-home-slide-dg1: #09173EC9;
  --ion-color-home-slide-dg2: #080D1D00;

  --backg-header-desktop: #021F74;

  --ion-color-box-shadow-desktop: #78809F34;
  --ion-color-bg-update: #021F74;

  --ion-color-skeleton-light: #F2F7FA;
  --ion-color-skeleton: #E4EEF5;

  --ion-color-shadow: #78809F66;
  --ion-color-success-green: #D6FFFA;


  /** border-radius **/
  --border-radius: 5px;
  --border-radius8: 8px;

  /** Global **/
  --ion-font-family: 'proxima-nova', sans-serif;

}

.popover-class {
  --backdrop-opacity: 0.35;
  --min-width: 300px;

  .popover-content {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    border-radius: 5px;
  }
}

ion-toast {
  transform: translateY(-130px);
}

.toast {
  font-size: 16px;
  font-weight: normal;
  --min-height: 59px;
  --max-width: 366px;
  --width: 100%;
  --start: auto;
  --end: 50px;
  --border-radius: 8px;
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-toast-border);
  --box-shadow: none;

  .toast-wrapper.toast-bottom {
    bottom: 90px !important;
  }
}

.notification-sheet {
  --button-color: var(--ion-color-dark);
  text-align: left;

  .action-sheet-wrapper {
    .action-sheet-container {
      .action-sheet-title {
        display: none;
      }

      .action-sheet-button {
        height: 48px;
        font-size: 16px;
        font-weight: 500;

        .action-sheet-button-inner {
          justify-content: flex-start;
        }
      }
    }
  }
}

/** Components **/
ion-content {
  --padding-start: 23px;
  --padding-end: 24px;
  --padding-top: 10px;
  --padding-bottom: 23px;
  --keyboard-offset: 0px !important;
  --background: var(--ion-color-white);
}

ion-header {
  &::after {
    background-image: unset !important;
  }
}

.header-md {
  box-shadow: none;
}

ion-icon {
  &:focus-visible {
    outline: none;
  }
}

ion-toolbar {
  // --padding-start: 16px;
  // --padding-end: 16px;

  ion-title {
    padding-inline: 0;
  }

  ion-buttons {
    &::slotted(.buttons-last-slot) {
      margin-inline-end: 0;
    }
  }
}

ion-button {
  --ripple-color: transparent;
}

ion-toggle {
  &::part(track) {
    height: 23px;
    width: 40px;
    --handle-width: calc(23px - (2px * 2));
    --track-background: var(--ion-color-light);

    /* Required for iOS handle to overflow the height of the track */
    overflow: visible;
  }
}

/** Extra **/
:focus {
  outline: none !important;
}

.icon-inner,
.ionicon,
svg {
  outline: none;
  border: none;
}

ion-icon {
  outline: none;
  border: none;

  .icon-inner,
  .ionicon,
  svg {
    outline: none;
    border: none;
  }
}



ion-card {
  margin: 0 !important;
}

ion-list {
  contain: unset;
}


swiper-container {
  overflow: visible !important;

  &::part(container) {
    overflow: visible !important;
  }

  swiper-slide {
    width: auto !important;

    // &.top-home-slide {
    //   margin-right: 0 !important;
    // }
  }

  &.inicio {
    swiper-slide {
      width: inherit !important;
      background: var(--ion-color-white);
    }
  }

  &.welcome {
    &::part(pagination) {
      position: static;
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &::part(bullet) {
      margin: 0 6px 0 0;
      height: 9px !important;
      width: 9px !important;
      border-radius: 100%;
      background: var(--ion-color-lines);
      opacity: 1;
    }

    &::part(bullet-active) {
      margin: 0 6px 0 0;
      height: 9px !important;
      width: 9px !important;
      border-radius: 100%;
      opacity: 1;
      background: var(--ion-color-pink);
    }
  }

  &.homeslide-desktop {
    .swiper-wrapper {
      width: 100% !important;
      height: 100% !important;
      margin: 0 auto;
      align-items: center;

      .swiper-slide {
        width: 100% !important;
        margin: 0 auto;
        overflow: hidden;
        height: 518px !important;
      }
    }

    &::part(pagination) {
      width: fit-content;
      border-radius: 19px;
      border: 1px solid var(--ion-color-white50);
      padding: 7px 8px 7px 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: absolute;
      left: 50%;
      bottom: 32px;
      transform: translateX(-50%);
      background-color: transparent;
      backdrop-filter: blur(21px);
    }

    &::part(bullet) {
      margin: 0 5px 0 0;
      height: 11px !important;
      width: 11px !important;
      border-radius: 100%;
      background: var(--ion-color-white50);
      opacity: 1;

      &:last-child {
        margin: 0;
      }
    }

    &::part(bullet-active) {
      margin: 0 5px 0 0;
      height: 11px !important;
      width: 11px !important;
      border-radius: 100%;
      background: var(--ion-color-pink);
    }

  }

  &.plans {
    &::part(wrapper) {
      width: 100%;
      max-width: 1000px;
    }
  }

  &.workcenter {
    .swiper-pagination-bullets {
      bottom: -32px;

      .swiper-pagination-bullet {
        background: rgba(var(--ion-color-light-rgb), .5);
        opacity: 1;
      }

      .swiper-pagination-bullet-active {
        opacity: 1;
        background: var(--ion-color-medium);
      }
    }
  }

  &.kam-slide {
    .swiper-pagination-bullets {
      bottom: -30px;

      .swiper-pagination-bullet {
        background: rgba(var(--ion-color-white-rgb), .2);
        opacity: 1;
      }

      .swiper-pagination-bullet-active {
        opacity: 1;
        background: var(--ion-color-white);
      }
    }
  }

  // &.top-home-slides {
  //   left: -20px !important;
  // }
}

ion-thumbnail {
  --border-radius: 8px;
}



ion-modal {
  --background: var(--ion-color-transparent) !important;
}


ion-datetime {
  --background: rgb(255 255 255);
  --background-rgb: 255, 255, 255;
  --wheel-highlight-background: var(--ion-color-skeleton-light);
  --wheel-highlight-border-radius: 48px;
}

ion-datetime::part(wheel-item) {
  color: var(--ion-color-primary);
}

ion-datetime::part(wheel-item active) {
  color: var(--ion-color-pink);
}

.datetime-modal {
  --box-shadow: none;
  --height: 100%;
}


figure.image {
  img {
    aspect-ratio: unset !important;
    width: 100% !important;
    height: auto !important;
  }
}

div {
  figure {
    width: 100% !important;
    margin: 1em auto !important;
  }
}


.show-desktop {
  display: none;
}


.modal-polls {
  display: flex;
  align-items: center;
  justify-content: center;
  --width: 600px;
  --height: 348px;

  .modal-wrapper {
    height: 600px;
    background: transparent !important;

    .modal-container {
      max-width: 600px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.modal-complete-profile {
  display: flex;
  align-items: center;
  justify-content: center;
  --width: 600px;
  --height: 344px;

  .modal-wrapper {
    height: 600px;
    background: transparent !important;

    .modal-container {
      max-width: 600px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.modal-enroll-event {
  display: flex;
  align-items: center;
  justify-content: center;
  --width: 392px;
  --height: 293px;

  .modal-wrapper {
    height: 293px;
    background: transparent !important;

    .modal-container {
      max-width: 392px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.modal-disclaimer {
  display: flex;
  align-items: center;
  justify-content: center;
  --width: 600px;
  --height: 210px;

  .modal-wrapper {
    height: 210px;
    background: transparent !important;

    .modal-container {
      max-width: 600px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.container-image {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
}


@media only screen and (min-width: 768px) and (min-height: 600px) {
  .sc-ion-modal-md-h:first-of-type {
    --box-shadow: none;
  }
}

@media only screen and (min-width: 768px) and (min-height: 768px) {

  .sc-ion-modal-md-h {
    --width: 640px;
    --height: 760px;

    &.modal-documents-options {
      --width: 567px;
      --height: 100%;
      --max-height: calc(100vh - 250px);
    }
  }

  .modal-plans-options {
    --width: 100%;
    --height: 100%;
  }
}



@media only screen and (min-width: 1200px) {
  .ng2-pdf-viewer-container {
    overflow-x: inherit !important;
  }


  ion-card {
    cursor: pointer;
    box-shadow: 0px 3px 15px var(--ion-color-box-shadow-desktop);
  }

  .split-panel {
    display: grid;
    grid-template-columns: 300px 1fr;

    &.more-videos {
      grid-template-columns: 1fr 360px;
      column-gap: 30px;
    }
  }

  .footer-container {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .button {
    cursor: pointer;
  }

  .show-desktop {
    display: block;
  }

  .show-mobile {
    display: none;
  }

  ion-loading.loading-gallery {
    --backdrop-opacity: 0;
    --spinner-color: var(--ion-color-primary);
    left: 100px;

    .loading-wrapper {
      --background: transparent;
      box-shadow: none;
    }

    ion-spinner {
      width: 40px !important;
      height: 40px !important;
    }
  }

  ion-loading.loading-gallery-detail {
    --backdrop-opacity: 0;
    --spinner-color: var(--ion-color-primary);
    left: 0;
    bottom: 60px;

    .loading-wrapper {
      --background: transparent;
      box-shadow: none;
    }

    ion-spinner {
      width: 40px !important;
      height: 40px !important;
    }
  }

  .sc-ion-modal-md-h {
    --width: 100%;
    --height: 100%;

    &.modal-documents-options {
      --width: 567px;
      --height: 100%;
      --max-height: calc(100vh - 250px);
    }
  }

  .gallery-modal {
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100%;

    &::part(content) {
      --width: 100%;
      --height: 100%;
      --background: transparent !important;
    }

    &::part(backdrop) {
      background-color: var(--ion-color-white);
    }
  }

  swiper-container {
    &.plans {
      &::part(wrapper) {
        justify-content: center;
      }
    }
  }
}
